import { useState, useMemo, useEffect } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  TitleWrapper,
  Title,
  TitleDescription,
  TableContainer,
  TableHeading,
  TableWrapper,
} from "../Styles";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "./ui/table";
import { v4 as uuidv4 } from "uuid";
import {
  useCreateTradeRequestMutationApi,
  useGetPaymentOptions,
  useUserTradingRequests,
} from "src/hooks/useTrading";
import { useQuery } from "@tanstack/react-query";
import { getCountries } from "src/services";
import ReactSelect from "react-select";
import { currencies } from "src/helpers/countriesWithCurrencies";
import { Button } from "./ui/button";
import { LucideDelete, LucideEdit } from "lucide-react";
import AddTradeRequest from "./AddTradeRequest";
import { useB2bTrade } from "src/hooks/useB2bTrading";
import ConfirmDeleteModal from "./ui/ConfirmDeleteModal";

const BUY = "BUY";
const SELL = "SELL";

interface PaymentOptionProps {
  payment_option: {
    name: string;
  };
}

const UserTradeOrders = ({
  businessId,
  businessName,
}: {
  businessId?: any;
  businessName?: string;
}) => {
  const [activeType, setActiveType] = useState(SELL);
  const [paymentFilter, setPaymentFilter] = useState<any>();
  const [countryFilter, setCountryFilter] = useState<any>("");
  const [currencyFilter, setCurrencyFilter] = useState<any>([]);
  const [focusedRequest, setFocusedRequest] = useState<any>({});
  const [tableData, setTableData] = useState<any>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [requestToDelete, setRequestToDelete] = useState({});
  const [page, setPage] = useState<number>(1);

  const isBuyRequests = activeType === SELL;
  const isRequestInFocus = Object.values(focusedRequest).length > 0;
  const isBusinessTrade = Boolean(businessId);

  const {
    data: tradingRequestsResponse,
    isFetching,
    isError,
    isLoading,
    refetch,
  } = useUserTradingRequests({
    tradeType: activeType,
    currencyCode: currencyFilter?.value,
    paymentMethods: paymentFilter?.slug,
    region: countryFilter?.code,
  });

  const {
    data: businessTradingRequestsResponse,
    isFetching: isFetchingBusinessTrades,
    isError: isBusinessTradeError,
    isLoading: isLoadingBusinessTrades,
    refetch: refetchBusinessOrders,
  } = useB2bTrade({
    businessId: businessId,
    tradeType: activeType,
    currencyCode: currencyFilter?.value,
    paymentMethods: paymentFilter?.slug,
    region: countryFilter?.code,
    page,
  });

  const userTradingRequests = tradingRequestsResponse?.results || [];
  const businessTradingRequests = businessTradingRequestsResponse?.results || [];
  const hasNextPage = tradingRequestsResponse?.next ? true : false;

  useEffect(() => {
    if (businessId) {
      setTableData(businessTradingRequests);
    } else {
      setTableData(userTradingRequests);
    }
  }, [businessId, businessTradingRequests, userTradingRequests]);

  useEffect(() => {
    refetchBusinessOrders();
  }, [businessId]);

  const { data: paymentOptionsResponse } = useGetPaymentOptions();
  const paymentOptions = paymentOptionsResponse || [];

  const { data: countries, isLoading: countriesIsLoading } = useQuery({
    queryKey: ["countries"],
    queryFn: () => getCountries(),
  });

  useEffect(() => {
    if (isBusinessTrade) {
      refetchBusinessOrders();
    } else {
      refetch();
    }
  }, [activeType, countryFilter, currencyFilter, paymentFilter]);
  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const columns = useMemo(
    () => [
      { accessorKey: isBusinessTrade ? "business_name" : "trading_name", header: "Advertiser" },
      {
        accessorKey: "conversion_rate_usd",
        header: "Rate",
        cell: ({ getValue }: { getValue: () => void }) => `USD ${getValue()}`,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        cell: ({ getValue }: { getValue: () => void }) => (
          <span className="grid">
            <span>{`·USD ${getValue()}`}</span>
          </span>
        ),
      },
      {
        accessorFn: (row: { min_amount_usd: string; max_amount_usd: string }) =>
          `USD ${row.min_amount_usd} - USD ${row.max_amount_usd}`,
        header: "Order Limit",
      },
      {
        accessorKey: "payment_methods",
        header: "Payment options",
        cell: ({ getValue }: { getValue: () => [] }) => (
          <span className="grid">
            {getValue().map((method: PaymentOptionProps) => (
              <span key={uuidv4()}>{method.payment_option.name}</span>
            ))}
          </span>
        ),
      },
      { accessorKey: "region", header: "Region" },
      {
        id: "actions",
        header: "Action",
        cell: ({ row }: any) => (
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              onClick={() => setFocusedRequest(row.original)}
              disabled={isLoading}
              style={{ background: "#fff5f0", color: "#B52C2C" }}
              className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
            >
              <LucideEdit height={13} width={13} style={{ marginRight: 8 }} /> Edit
            </Button>
            <Button
              onClick={() => {
                setRequestToDelete(row.original);
                setShowDeleteDialog(true);
              }}
              disabled={isLoading}
              style={{ background: "#fff5f0", color: "#B52C2C" }}
              className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
            >
              <LucideDelete height={15} width={15} style={{ marginRight: 8 }} /> Delete
            </Button>
          </div>
        ),
      },
    ],
    [isBusinessTrade, isLoading],
  );

  const table = useReactTable({
    data: isBusinessTrade ? businessTradingRequests : userTradingRequests,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
  });

  return (
    <div style={{ marginTop: "2em" }}>
      <TableContainer>
        <TableHeading>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button
              onClick={() => setActiveType(SELL)}
              // disabled={isLoading}
              style={{ background: isBuyRequests ? "#fff5f0" : "#fff", color: "#B52C2C" }}
              className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
            >
              Buy
            </Button>
            <Button
              onClick={() => setActiveType(BUY)}
              // disabled={isLoading}
              style={{ background: !isBuyRequests ? "#fff5f0" : "#fff", color: "#B52C2C" }}
              className="flex items-center justify-center rounded-md bg-red-500 px-6 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-400 mx-1"
            >
              Sell
            </Button>
          </div>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mr-3">
              <ReactSelect
                onChange={(option: any) => setPaymentFilter(option)}
                options={paymentOptions}
                isClearable={true}
                placeholder="Payment"
                isDisabled={isFetching}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.slug}
                value={paymentFilter}
              />
            </div>
            <div className="grid w-[200px] max-w-sm items-center gap-1.5 m-1rem mr-3">
              <ReactSelect
                options={currencies}
                placeholder="Currency"
                formatOptionLabel={(option) => `${option.value} - ${option.label}`}
                isClearable
                isSearchable
                isDisabled={isFetching}
                onChange={(option) => {
                  setCurrencyFilter(option?.value || "");
                }}
              />
            </div>

            <div className="grid w-[250px] max-w-sm items-center gap-1.5 m-1rem">
              <ReactSelect
                onChange={(option: any) => setCountryFilter(option)}
                options={countries}
                isClearable={true}
                placeholder="Region"
                isDisabled={countriesIsLoading}
                value={countryFilter}
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.value}
              />
            </div>
          </div>
        </TableHeading>
        <TableWrapper height="calc(100vh - 400px)">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Loading...
                  </TableCell>
                </TableRow>
              ) : isError ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Error fetching data
                  </TableCell>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    <button onClick={() => refetch()}>Refetch</button>
                  </TableCell>
                </TableRow>
              ) : tableData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center", paddingTop: "5rem" }}>
                    Ooops, there are no items at the moment.
                  </TableCell>
                </TableRow>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id} style={{ fontSize: "0.85rem", color: "#707070" }}>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableWrapper>
        <div className="flex justify-center mt-4 mb-4">
          <button
            onClick={handlePrevPage}
            disabled={!table.getCanPreviousPage()}
            className={`btn ${!table.getCanPreviousPage() ? "cursor-not-allowed opacity-50" : ""}`}
          >
            {"<<  Previous"}
          </button>
          <span className="mx-4">
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </span>
          <button
            onClick={handleNextPage}
            disabled={!table.getCanNextPage()}
            className={`btn ${!table.getCanNextPage() ? "cursor-not-allowed opacity-50" : ""}`}
          >
            {"Next >>"}
          </button>
        </div>
      </TableContainer>
      {isRequestInFocus && (
        <AddTradeRequest
          open={isRequestInFocus}
          handleClose={() => setFocusedRequest({})}
          countries={countries}
          countriesIsLoading={countriesIsLoading}
          tradeRequest={focusedRequest}
          businessId={businessId}
          businessName={businessName}
        />
      )}
      {showDeleteDialog && (
        <ConfirmDeleteModal
          open={showDeleteDialog}
          handleClose={() => setShowDeleteDialog(false)}
          request={requestToDelete}
          businessId={businessId}
        />
      )}
    </div>
  );
};

export default UserTradeOrders;
